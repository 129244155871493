import React, { useState } from 'react'
import { useQueryParam, StringParam } from "use-query-params";
import { Composition } from 'atomic-layout'
// import { Text, Button, Image } from '../../atoms'
import { Flex,Input as MyInput, Label, Text, Box } from 'theme-ui';
import { useForm } from 'react-hook-form';
import MyButton from './Button';
import HypefastLogo from "../assets/hypefast.svg";
import { useMutation } from 'react-query';
import api from '../utils/api';
import { navigate } from 'gatsby';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import IconButton from './Editor/IconButton';
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa';
import { InputComponent } from './Editor/Input';
import Toast from './Editor/Toast';

interface ResetPasswordData {
  password: string;
  password_confirmation: string;
}

const schema: Yup.SchemaOf<ResetPasswordData> = Yup.object({
  password: Yup.string().required('Password wajib diisi!'),
  password_confirmation: Yup.string().required('Password wajib diisi!')
    .oneOf([Yup.ref('password')], 'Password tidak sesuai!'),
});

const areasMobile = `
  header
  password
  passwordConfirmation
  button
  footer
`

const ResetPassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [email] = useQueryParam("email", StringParam);
  const [token] = useQueryParam("token", StringParam);

  const {
    register, handleSubmit, formState: { errors },
  } = useForm({
    resolver: yupResolver(schema)
  });

  const onSuccess = () => {
    navigate('/');
    Toast({
      title: 'Reset Berhasil',
      message: 'Password berhasil direset',
      type: 'success',
    });
  };

  const onError = (error: any) => {
    if (error) {
      console.log(error);
    }
  };
  
  const {
    isLoading,
    mutate,
  } = useMutation(({ password }) => api.resetPassword({
    email,
    token,
    password,
  }), { onSuccess, onError });

  return (
    <Flex
      sx={{
        backgroundColor: '#f8f8f8',
        height: '100vh',
        alignItems: 'center',
        px: 5,
      }}
    >
      <Composition
        width={'100%'}
        inline
        // as={Container}
        areas={areasMobile}
        templateCols={'1fr'}
        gap={20}
        padding={32}
      >
        {({ Header, Password, Button, Footer, PasswordConfirmation }) => (
          <>
            <Header>
              <Box
                py={4}
              >
                <HypefastLogo
                  width={'100%'}
                />
              </Box>
              <Box
                py={2}
              >
                <Text
                  variant='headline'
                >
                  Awesome!
                </Text>
              </Box>
              <Box
                py={2}
              >
                <Text
                  variant='body'
                >
                  Your indentity has been verified, set your new password!
                </Text>
              </Box>
            </Header>
            <Password>
              {/* <InputComponent
                isPassword
                isInvalid={!!errors.password}
                label="Password"
                errorMessage={errors?.password?.message}
                {...register("password")}
              /> */}
              <Box
                py={1}
              >
                <Label>
                  <Text
                    variant='body'
                    // sx={{
                    //   color: '#332f11',
                    // }}
                  >
                    Password
                  </Text>
                </Label>
              </Box>
              <Flex>
                <MyInput
                  // sx={{
                  //   boxShadow: 'inset 0 2px 2px, 0 2px 0',
                  //   backgroundColor: '#d7cd74',
                  // }}
                  placeholder="Password"
                  variant={errors.password && 'error' || 'password'}
                  type={showPassword ? 'text' : 'password'}
                  {...register("password")}
                />
                <IconButton
                  icon={showPassword ? <FaRegEyeSlash /> : <FaRegEye />}
                  onClick={() => setShowPassword(!showPassword)}
                  style={{
                    position: 'relative',
                    right: '30px'
                  }}
                />
              </Flex>
              {errors.password && (
                <Text color={'error'} sx={{ fontSize: '12px'}}>{errors.password.message}</Text>
              )}
            </Password>
            <PasswordConfirmation>
              {/* <InputComponent
                isPassword
                isInvalid={!!errors.password_confirmation}
                label="Password Confirmation"
                errorMessage={errors?.password_confirmation?.message}
                {...register("password_confirmation")}
              /> */}
              <Box
                py={1}
              >
                <Label>
                  <Text
                    variant='body'
                    // sx={{
                    //   color: '#332f11',
                    // }}
                  >
                    Password Confirmation
                  </Text>
                </Label>
              </Box>
              <Flex>
                <MyInput
                  // sx={{
                  //   boxShadow: 'inset 0 2px 2px, 0 2px 0',
                  //   backgroundColor: '#d7cd74',
                  // }}
                  placeholder="Password Confirmation"
                  variant={errors.password_confirmation && 'error' || 'password'}
                  type={showPass ? 'text' : 'password'}
                  {...register("password_confirmation")}
                />
                <IconButton
                  icon={showPass ? <FaRegEyeSlash /> : <FaRegEye />}
                  onClick={() => setShowPass(!showPass)}
                  style={{
                    position: 'relative',
                    right: '30px'
                  }}
                />
              </Flex>
              {errors.password_confirmation && (
                <Text color={'error'} sx={{ fontSize: '12px'}}>{errors.password_confirmation.message}</Text>
              )}
            </PasswordConfirmation>
            <Button>
              <MyButton
                full
                isLoading={isLoading}
                variant='primary'
                sx={{
                  // backgroundColor: '#332f11',
                }}
                onClick={handleSubmit(mutate)}
              >
                <Text
                  variant='subtitle'
                  // sx={{
                  //   color: '#ffe07c'
                  // }}
                >
                  Reset Password
                </Text>
              </MyButton>
            </Button>
            <Footer>
              {/* <Button variant='primary'>Buy</Button> */}
            </Footer>
          </>
        )}
      </Composition>

    </Flex>
  )
}

export default ResetPassword
