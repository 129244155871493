import * as React from 'react'
import ResetPassword from '../components/ResetPassword'
import Page from '../components/Layout/Page';


// markup
const ForgotPasswordPage = () => {
  return (
    <Page>
      <ResetPassword/>
    </Page>
  )
}

export default ForgotPasswordPage
